/* eslint-disable react/display-name */
import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';

import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Button } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import { Icon } from 'src/components/atoms/Icon';
import { List, ListItem } from 'src/components/atoms/List';
import { Grid } from 'src/components/molecules/Grid';
import { Badge } from 'src/components/atoms/Badge';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  hr {
    border-color: rgba(128, 128, 128, 0.4);
  }
`;

const Header = styled.div`
  padding: 1.5rem 1.5rem 0;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`;

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.primaryColor};
`;

const Content = styled.div`
  flex: 1;
  padding: 0 1.5rem 0;
`;

const Footer = styled.div`
  text-align: center;
  padding: 0 1rem 0;
  margin-bottom: 1.75rem;
  margin-left: auto;
  margin-right: auto;
`;

const PrimaryBadge = styled(Badge)`
  background-color: white;
  color: black;
  font-size: 0.75em;
`;

export default function DrawerProductPricingNew({ heading, callouts, customData, ctas }) {
  const flags = JSON.parse(customData || '{}').flags;

  return (
    <Section {...flags}>
      {!!heading && (
        <SectionHeader>
          <h2>{heading}</h2>
        </SectionHeader>
      )}
      <Grid columns="1fr 1fr 1fr">
        {callouts.map((callout, i) => {
          const { heading, preHeading, preContentNode, preContent, contentNode, ctas } = callout;
          const calloutFlags = JSON.parse(callout.customData)?.flags || {}
          const calloutBadge = JSON.parse(callout.customData)?.badge;

          return (
            <Container key={i} small {...{ alternative: flags.alternative, ...calloutFlags }}>
              <Header>
                <Title>{heading}</Title>
                {!!calloutBadge && (
                  <h3>
                    <PrimaryBadge>Rasa Pro</PrimaryBadge>&nbsp;&nbsp;+&nbsp;&nbsp;
                    <PrimaryBadge>Rasa Studio</PrimaryBadge>
                  </h3>
                )}
                {!!preHeading && <Subtitle>{preHeading}</Subtitle>}
                <MDXProvider>
                  {preContent && <MDXRenderer>{preContentNode.childMdx.body}</MDXRenderer>}
                </MDXProvider>
              </Header>
              {!!ctas.length && (
                <Footer>
                  {ctas.map((cta, i) => {
                    const customData = cta.customData ? JSON.parse(cta.customData) : {};

                    return (
                      <Button
                        noMargin
                        expanded
                        to={cta.url}
                        aria-label={cta.a11yText}
                        key={i}
                        {...customData}
                      >
                        {cta.text}
                      </Button>
                    );
                  })}
                </Footer>
              )}
              <Content>
                <MDXProvider
                  components={{
                    Button,
                    ul: List,
                    li: ({ children }) => (
                      <ListItem
                        icon={<Icon icon={['fas', 'check-circle']} color="var(--primary-color)" size="sm" />}
                      >
                        {children}
                      </ListItem>
                    ),
                  }}
                >
                  <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>
                </MDXProvider>
              </Content>
            </Container>
          );
        })}
      </Grid>
      {flags.alternative &&
        ctas.map((cta, i) => (
          <Button to={cta.url} aria-label={cta.a11yText} key={i}>
            {cta.text}
          </Button>
        ))}
    </Section>
  );
}
